import React, { useEffect, useState } from 'react'
import {
  Switch,
  Route,
  useRouteMatch,
  useHistory,
  useLocation,
} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import SearchResultList from '@brainbay/components/components/search-result-list'
import { trackEvent, trackPageView } from '@brainbay/components/utils/ga-events'
import { gaCategories } from '@brainbay/components/utils/constants'
import Paginaton from '@brainbay/components/components/pagination'
import SearchResultItem from '@brainbay/components/components/search-result-item'
import { setSearchResult as setPageTitle } from '../store/page-title'
import { removeExportButton } from '../store/export-button'
import { setSearchQuery, setShowForSaleOnly } from '../store/search'
import isSSR from '@brainbay/components/utils/is-ssr'
import ApiData from '../utils/api-data'
import formatUrl from '@brainbay/components/utils/format-url'

import './search-result.css'

export default function SearchResults() {
  const [searchQueryValue, setSearchQueryValue] = useState('')
  const history = useHistory()
  const match = useRouteMatch()
  const location = useLocation()
  const dispatch = useDispatch()
  const pageUri = match.path
  const forSaleOnlyValue = useSelector(state => state.search.showForSaleOnly)
  const numberOfItems = 10

  useEffect(() => {
    dispatch(setPageTitle())
    dispatch(removeExportButton())
  }, [dispatch])

  useEffect(() => {
    trackPageView('visit_page_search', 'Searchpage opened')
  }, [])

  useEffect(() => {
    const searchQuery = location.pathname.split('/')[2]
    setSearchQueryValue(searchQuery)
  }, [location])

  useEffect(() => {
    dispatch(setSearchQuery(searchQueryValue))
  }, [dispatch, searchQueryValue])

  function handleToggleChange(event) {
    dispatch(setShowForSaleOnly(event.target.checked))
    if (event.target.checked) {
      trackEvent(
        'filter',
        gaCategories.SEARCH,
        'Toggle filter from "all" to "Midas only"',
      )
    } else {
      trackEvent(
        'filter',
        gaCategories.SEARCH,
        'Toggle filter from "Midas only" to "all"',
      )
    }
    history.push(`${pageUri}/${searchQueryValue}/`)
  }

  return (
    <main className="layout--grid">
      <Switch>
        <Route
          path={`${pageUri}/:query/:page?`}
          render={({ match }) => {
            const page = match.params.page || 1
            const query = match.params.query
            const skip = page * numberOfItems - numberOfItems

            return (
              <ApiData
                path={
                  forSaleOnlyValue
                    ? `objects?q=${query}&skip=${skip}&take=${numberOfItems}&inNetherlandsOnly=true`
                    : `address?query=${query}&skip=${skip}&take=${numberOfItems}`
                }
                error={() => (
                  <p className="body-big">
                    We kunnen de data voor <q>{query}</q>{' '}
                    {page > 1 && `op pagina ${page}`} niet ophalen
                  </p>
                )}
                render={({ response }) => {
                  const loadingData = [
                    { id: 'a', objects: [{ objectGuid: 'a' }] },
                    { id: 'b', objects: [{ objectGuid: 'a' }] },
                    { id: 'c', objects: [{ objectGuid: 'a' }] },
                  ]
                  const data = response?.data ?? loadingData
                  const hasResults = Boolean(response?.data?.length)

                  return (
                    <>
                      <SearchResultList
                        query={query}
                        hasResults={hasResults}
                        isLoading={response?.loading}
                        filterOptions={
                          <>
                            <input
                              id="search-toggle"
                              type="checkbox"
                              role="switch"
                              checked={forSaleOnlyValue}
                              data-side-of-label="right"
                              onChange={handleToggleChange}
                            />
                            <label htmlFor="search-toggle">
                              Alleen zoeken in Midas-database
                            </label>
                          </>
                        }
                        filterDescription={
                          !forSaleOnlyValue ? (
                            <p className="search-result__filter-description body">
                              Ontbreekt er een adres dat wel in Midas is
                              aangemeld? Wijzig dan de schuifknop naar 'Alleen
                              zoeken in Midas-database'.
                            </p>
                          ) : null
                        }
                        explanation="Is deze woning wel in Midas aangemeld? Wijzig dan de schuifknop naar 'Alleen zoeken in Midas-database'."
                        error={response?.error}
                      >
                        {data.map(address => {
                          const latestObject =
                            address?.objects?.length > 0
                              ? address.objects[0]
                              : null

                          const isBAGObject = address.objectGuid === undefined

                          return (
                            <SearchResultItem
                              key={address.id}
                              linkTo={formatUrl({
                                housingType: address.soortenEnType,
                                addressId: address.adres,
                                id: latestObject
                                  ? latestObject.objectGuid
                                  : address.id,
                              })}
                              isLoading={response?.loading}
                              rooms={address.aantalKamers}
                              hideDateAdded={
                                latestObject || !isBAGObject ? false : true
                              }
                              date={
                                latestObject?.transactieDatum ||
                                address.transactieDatum ||
                                latestObject?.aanmelddatum ||
                                address.aanmelddatum
                              }
                              housenumber={address.huisnummer}
                              housenumberSuffix={address.huisnummertoevoeging}
                              mediaGuid={address.mediaGuid}
                              propertyArea={address.perceeloppervlakte}
                              postalCode={address.postcode}
                              hidePrice={
                                latestObject || !isBAGObject ? false : true
                              }
                              askingPrice={
                                latestObject?.vraagprijs ||
                                address.vraagprijs ||
                                null
                              }
                              soldPrice={
                                latestObject?.transactieprijs ||
                                address.transactieprijs ||
                                null
                              }
                              priceCondition={
                                latestObject?.verhuurconditie ||
                                (latestObject?.transactieprijs &&
                                  latestObject?.transactieConditie) ||
                                latestObject?.verkoopconditie ||
                                address.verhuurconditie ||
                                (address.transactieprijs &&
                                  address.transactieConditie) ||
                                address.verkoopconditie ||
                                null
                              }
                              priceStatus={
                                latestObject?.prijsvoorvoegsel || null
                              }
                              source={
                                latestObject || !isBAGObject ? 'MIDAS' : 'BAG'
                              }
                              hideHousingType={!address.soortenEnType}
                              housingType={address.soortenEnType}
                              status={
                                latestObject?.status || address.status || null
                              }
                              streetname={address.straatnaam}
                              livingArea={address.woonoppervlakte}
                              city={address.woonplaats}
                            />
                          )
                        })}
                      </SearchResultList>
                      <Paginaton
                        page={Number(page)}
                        count={response?.count}
                        pageSize={numberOfItems}
                        rootUri={`${pageUri}/${query}`}
                      />
                    </>
                  )
                }}
              />
            )
          }}
        />
        <Route
          path={`${pageUri}/`}
          exact
          render={() =>
            isSSR ? (
              <SearchResultList query={' '} />
            ) : (
              <p className="body-big">Voer een adres in het zoekveld in</p>
            )
          }
        />
      </Switch>
    </main>
  )
}
