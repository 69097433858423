import React, { useEffect, useMemo, useState } from 'react'
import { Switch, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import useObjectDetails from '../../utils/use-object-details'
import ModalReferences from '../modal-references'
import Panel from '@brainbay/components/components/panel'
import AddNoteButton from '@brainbay/components/components/add-note-button'
import ModalNote from '@brainbay/components/components/modal-note'
import Modal from '@brainbay/components/components/modal'
import ReferencesNotification from '@brainbay/components/components/references-notification'
import ObjectList from '@brainbay/components/components/object-list'
import ObjectListItem from '@brainbay/components/components/object-list-item'
import { trackEvent } from '@brainbay/components/utils/ga-events'
import { gaCategories } from '@brainbay/components/utils/constants'
import formatUrl from '@brainbay/components/utils/format-url'
import getErfpachtComparisonText from '@brainbay/components/utils/get-erfpacht-comparison-text'
import showErfpachtInReferences from '@brainbay/components/utils/show-erfpacht-in-references'
import useReference from '../../utils/use-reference'

import './object-references.css'

const ReferencesList = ({
  objectId,
  data,
  isLoading,
  onDeleteReference,
  onResetReferences,
  referencesUpdatedAt,
  loadingReferenceId,
  baseUrl,
}) => {
  const [showErfpachtValues, setShowErfpachtValues] = useState(false)

  const { objectDetailsFromStore } = useObjectDetails()

  const references = isLoading
    ? [{ objectGuid: 'a' }, { objectGuid: 'b' }, { objectGuid: 'c' }]
    : data

  useMemo(() => {
    setShowErfpachtValues(
      showErfpachtInReferences(objectDetailsFromStore, data),
    )
  }, [data, objectDetailsFromStore])

  function handleDeleteReference(referenceGuid) {
    trackEvent(
      'clicked_delete_reference_from_object-page',
      gaCategories.REFERENCES,
      `Deleted reference ${referenceGuid} on object-page for object ${objectId}`,
    )

    onDeleteReference(referenceGuid)
  }

  function handleResetReferences() {
    trackEvent(
      'clicked_reset_references_from_object_page',
      gaCategories.REFERENCES,
      `Reset references from object page for ${objectId}`,
    )

    onResetReferences()
  }

  return (
    <>
      <ReferencesNotification
        className="object-references__notification"
        onResetReferences={referencesUpdatedAt && onResetReferences}
        referencesUpdatedAt={referencesUpdatedAt}
      />
      <ObjectList
        isLoading={isLoading}
        referencesLink={`${baseUrl}/references`}
        objectDeleteButton
        onObjectDelete={handleDeleteReference}
        onResetReferences={referencesUpdatedAt && handleResetReferences}
        referencesUpdatedAt={referencesUpdatedAt}
        deletedObjectId={loadingReferenceId}
      >
        {references.map(item => {
          return (
            <ObjectListItem
              address={item.adres?.adresString}
              askingCondition={item.verkoopconditie}
              askingPrice={item.vraagprijsEuro}
              askingPricePerSquareMeter={item.vraagprijsPerM2}
              duration={
                item.verkooptijdDagen !== undefined
                  ? item.verkooptijdDagen
                  : item.looptijdDagen
              }
              energieLabel={item.energieLabel}
              erfpacht={getErfpachtComparisonText(item.erfpacht)}
              grossContents={item.brutoInhoudM3}
              housingTypes={item.woningType?.soortenEnType}
              id={item.objectGuid}
              indexedPurchasePrice={item.geindexeerdeTransactieprijsEuro}
              indexedPurchasePricePerSquareMeter={
                item.geindexeerdeTransactieprijsPerM2
              }
              isLoading={isLoading}
              key={item.objectGuid}
              linkTo={formatUrl({
                housingType: [
                  item.woningType?.typeWoning ||
                    item.woningType?.soortAppartement,
                ],
                addressId: item?.adres?.id,
                id: item.objectGuid,
              })}
              livingArea={item.woonoppervlakteM2}
              match={item.matchingsPercentage}
              mediaGuids={item.mediaGuids}
              objectGuid={item.objectGuid}
              priceDifference={item.prijsVerschilEuro}
              priceDifferencePercentage={item.prijsVerschilPercentage}
              propertyArea={item.perceeloppervlakteM2}
              qualityScore={item.woningKwaliteit}
              rentCondition={item.verhuurconditie}
              rentPrice={item.huurprijsEuro}
              rentPricePerSquareMeter={item.huurprijsPerM2}
              rooms={item.aantalKamers}
              showErfpacht={showErfpachtValues}
              soldCondition={item.transactieconditie}
              soldDate={item.transactieDatum}
              soldPrice={item.verkoopprijsEuro}
              soldPricePerSquareMeter={item.verkoopprijsPerM2}
              status={item.status}
              yearOfConstruction={item.bouwjaar}
              yearOfConstructionFrom={item.bouwjaarVan}
              yearOfConstructionTo={item.bouwjaarTot}
            />
          )
        })}
      </ObjectList>
    </>
  )
}

export default function ObjectReferences({
  objectId,
  baseUrl,
  onCloseModal,
  onShowNoteModal,
  onSaveNote,
}) {
  const {
    referencesUpdatedAt,
    referencesAreLoading,
    referencesHasError,
    loadingReferenceId,
    selectedReferences,
    getReferences,
    deleteReference,
    addReference,
    resetReferences,
  } = useReference(objectId)
  const excludedPrintItems = useSelector(state => state.printSettings)
  const notes = useSelector(
    state => state.user?.data?.[objectId]?.notes?.references,
  )

  useEffect(() => {
    getReferences()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectId])

  return (
    <div
      className={
        excludedPrintItems.indexOf('references') >= 0 ? 'print-excluded' : ''
      }
    >
      <Panel
        title="Referenties"
        moreInfoLabel="Over de methodiek"
        moreInfoUrl={`${baseUrl}/info/references`}
        action={
          <AddNoteButton
            onButtonClicked={() => onShowNoteModal('references')}
            hasBeenAdded={notes}
          />
        }
      >
        <Switch>
          <Route path={`${baseUrl}/info/references`}>
            <Modal onClose={onCloseModal} title="Referenties">
              <p>
                Getoond worden de referentiewoningen die qua locatie- en
                woningkenmerken de hoogste match hebben met de door u gekozen
                woning. U kunt woningen toevoegen of verwijderen.
              </p>
              <p>
                Het matchingspercentage wordt vastgesteld aan de hand van de
                actualiteit van de transactie en in hoeverre de referentie
                matcht met de gekozen woning op basis van locatie, woningtype,
                bouwperiode, woon- en kaveloppervlak en actualiteit.
              </p>
            </Modal>
          </Route>
          <Route path={`${baseUrl}/note/references`}>
            <ModalNote
              onClose={onCloseModal}
              panel="references"
              panelTitle="Referenties"
              notes={notes}
              onSaveNote={onSaveNote}
            />
          </Route>
          <Route path={`${baseUrl}/references`}>
            <ModalReferences
              onClose={onCloseModal}
              objectId={objectId}
              baseUrl={`${baseUrl}`}
              selectedReferences={selectedReferences}
              onResetReferences={referencesUpdatedAt && resetReferences}
              onAddReference={addReference}
              onDeleteReference={deleteReference}
              loadingReferenceId={loadingReferenceId}
            />
          </Route>
        </Switch>

        {!referencesHasError ? (
          <>
            {selectedReferences?.length > 0 || referencesAreLoading ? (
              <ReferencesList
                objectId={objectId}
                data={selectedReferences}
                isLoading={referencesAreLoading}
                baseUrl={baseUrl}
                onDeleteReference={deleteReference}
                onResetReferences={resetReferences}
                loadingReferenceId={loadingReferenceId}
                referencesUpdatedAt={referencesUpdatedAt}
              />
            ) : (
              <>
                <p className="body">
                  Voor deze woning kunnen helaas geen referenties worden
                  getoond. Zoek naar referenties door op de knop te drukken.
                </p>
                <ObjectList referencesLink={`${baseUrl}/references/search`} />
              </>
            )}
          </>
        ) : (
          <>
            <p className="body">
              Voor deze woning kunnen helaas geen referenties worden getoond.
              Zoek naar referenties door op de knop te drukken.
            </p>
            <ObjectList referencesLink={`${baseUrl}/references/search`} />
          </>
        )}
      </Panel>

      {notes && (
        <Panel
          className={`print-only${
            excludedPrintItems.indexOf('references-note') >= 0
              ? ' print-excluded'
              : ''
          }`.trim()}
          divider={false}
        >
          <p className="body text-pre">{notes}</p>
        </Panel>
      )}
    </div>
  )
}
