import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ObjectList from '@brainbay/components/components/object-list'
import ObjectListItemSmall from '@brainbay/components/components/object-list-item-small'
import Feedback from '@brainbay/components/components/feedback'
import { trackPageView } from '@brainbay/components/utils/ga-events'
import useUserData from '../utils/use-user-data'
import { setLanding as setPageTitle } from '../store/page-title'
import { clearValue } from '../store/search'

import './landing.css'

export default function Landing() {
  const dispatch = useDispatch()
  const { getUserData } = useUserData()
  const recentVisits =
    useSelector(state => state.user?.data?.['recent-visits']) || []
  const userName = useSelector(state => state.user?.user?.sub)

  useEffect(() => {
    trackPageView('visit_page_landing', 'Landingpage opened')

    if (!recentVisits.length) {
      getUserData(userName, 'recent-visits')
    }

    // We don't want the side effects of `getUserData` triggering this `useEffect`.
    // We only want to trigger this `useEffect` one time, when mounting this page.
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    dispatch(setPageTitle())
    dispatch(clearValue())
  }, [dispatch])

  const ratingsUrl =
    window.deploy_env === 'prd'
      ? 'https://ratings.brainbay.nl/widget?appName=Inverkoopname&widgetToken=394f6480-c8a3-4a33-9c6f-2826f9b431fc'
      : 'https://ratings.acc.brainbay.nl/widget?appName=Inverkoopname&widgetToken=1234'

  return (
    <>
      <main className="landing layout--grid">
        <h2 className="object-list-item-small__header-text h4 text-bold">
          Recent bekeken adressen
        </h2>
        {recentVisits.length ? (
          <ObjectList small>
            {recentVisits.map(item => {
              return (
                <ObjectListItemSmall
                  key={item.objectGuid || item.id}
                  id={item.objectGuid || item.id}
                  mediaGuid={item.mediaGuid}
                  addressId={item.addressId}
                  housing={item.housing}
                  address={item.address}
                  date={new Date(item.date)}
                />
              )
            })}
          </ObjectList>
        ) : (
          <p className="object-list-item-small__header-text body">
            Begin met zoeken om recent bekeken adressen te zien.
          </p>
        )}
      </main>
      <Feedback className="landing__feedback" src={ratingsUrl} />
    </>
  )
}
