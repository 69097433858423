import React from 'react'
import {
  DefaultLayout,
  Text,
  View,
} from '@brainbay/components/utils/pdf-components'
import formatValue from '@brainbay/components/utils/format-value'

export default function DemographicsPage({
  corporateIdentity,
  item: demographics,
  userName,
  toolName,
  date,
  debug,
}) {
  const renderDemographics = demographics?.herkomstKopers || []

  return (
    <DefaultLayout
      pageName="Doelgroepen"
      corporateIdentity={corporateIdentity}
      userName={userName}
      toolName={toolName}
      date={date}
      debug={debug}
    >
      <View
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
        }}
      >
        <Text
          style={{
            fontSize: 18,
            fontWeight: 'bold',
            marginBottom: 30,
            marginTop: 20,
          }}
        >
          Herkomst kopers
        </Text>
        {renderDemographics.map((item, index) => (
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginBottom: 20,
              width: '25%',
            }}
            key={index}
          >
            <View style={{ width: '50%' }}>
              <Text style={{ fontSize: 22, fontWeight: 'bold' }}>
                {formatValue(item?.percentage, { format: 'percent' })}
              </Text>
            </View>
            <View style={{ width: '50%' }}>
              <Text style={{ fontSize: 12, fontWeight: 'normal' }}>
                {item?.regio || '---'}
              </Text>
            </View>
          </View>
        ))}
        <Text style={{ fontSize: 10, color: '#888888', marginTop: 10 }}>
          {demographics?.extraInformatie}
        </Text>
      </View>
    </DefaultLayout>
  )
}
