import React, { useState, useEffect, useMemo } from 'react'
import {
  Switch,
  Route,
  useHistory,
  useRouteMatch,
  useLocation,
} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Modal from '@brainbay/components/components/modal'
import Panel from '@brainbay/components/components/panel'
import PrintSettingsForm from '@brainbay/components/components/print-settings-form'
import NotificationPanel from '@brainbay/components/components/notification-panel'
import tabElements from '@brainbay/components/utils/tab-elements'
import TabbedContent from '@brainbay/components/components/tabbed-content'
import { toolName } from '@brainbay/components/utils/constants'
import ObjectDetails from '../components/object/object-object-details'
import History from '../components/object/object-history'
import Valuation from '../components/object/object-valuation'
import References from '../components/object/object-references'
import MarketValue from '../components/object/object-market-value'
import Demographics from '../components/object/object-demographics'
import Feedback from '@brainbay/components/components/feedback'
import { trackPageView, trackEvent } from '@brainbay/components/utils/ga-events'
import { gaCategories } from '@brainbay/components/utils/constants'
import useUserData from '../utils/use-user-data'
import useExport from '../utils/use-export'
import { showExportButton } from '../store/export-button'
import {
  setExcludedItem,
  deleteExcludedItem,
  deleteAllExcludedItems,
} from '../store/print-settings'
import useObjectDetails from '../utils/use-object-details'
import './object.css'
import { useRef } from 'react'

export default function ObjectView(props) {
  const dispatch = useDispatch()
  const match = useRouteMatch()
  const history = useHistory()
  const location = useLocation()

  const housingType = match.params.housingType
  const addressId = match.params.addressId
  const objectId = match.params.id

  const { getUserData, setUserData } = useUserData()
  const hasObject = useSelector(state => state.user?.data?.[objectId])
  const hasRecentVisits = useSelector(
    state => state.user?.data?.['recent-visits'],
  )
  const userName = useSelector(state => state.user?.user?.sub)
  const userRole = useSelector(state => state?.user?.user?.Role)
  const valuationData = useSelector(state => state.valuation.valuation)
  const demographicsFromStore = useSelector(state => state?.demographics)
  const marketFromStore = useSelector(state => state?.market)
  const referencesFromStore = useSelector(
    state => state?.selectedReferences?.references,
  )

  const [renderReferencesNotToExport, setRenderReferencesNotToExport] =
    useState(false)

  const urlParts = [housingType, addressId, objectId].filter(item => item)
  const baseUrl = `/${urlParts.join('/')}`

  const { objectDetailsFromStore } = useObjectDetails()
  const isLoading = Object.keys(objectDetailsFromStore).length === 0

  const canExportJson =
    userRole && userRole.indexOf('inverkoopname_export') !== -1
  const excludedPrintItems = useSelector(state => state.printSettings)
  const { makePDF, makeJSON } = useExport()

  const graphRef = useRef(null)

  const originalValues = objectDetailsFromStore?.originalValues
  const numberOfOriginalValues = originalValues
    ? Object.keys(originalValues).length
    : null
  const objectDetailsHasChanged = numberOfOriginalValues > 0

  const isBAGObject = objectDetailsFromStore?.objectGuid === undefined

  const isOldTransaction = useMemo(() => {
    const aanmeldDatum = new Date(objectDetailsFromStore.aanmelddatum)
    const today = new Date()
    const fiveYearsAgo = new Date(today.setFullYear(today.getFullYear() - 5))

    return fiveYearsAgo >= aanmeldDatum
  }, [objectDetailsFromStore])

  const aanmeldYear = new Date(
    objectDetailsFromStore?.aanmelddatum,
  ).getFullYear()

  const ageOfObject = new Date().getFullYear() - aanmeldYear

  const isModuleValuationVisible =
    valuationData?.prijsOntwikkeling ||
    valuationData?.prijsModel ||
    (referencesFromStore?.length > 0 && objectDetailsFromStore?.woonoppervlakte)

  let exportObjectSections = useMemo(() => {
    return [
      ...(Object.keys(objectDetailsFromStore)?.length > 0
        ? [
            {
              key: 'object-details',
              name: 'woningdetails',
              ...(objectDetailsFromStore.bijzonderheden && {
                subSections: [{ key: 'particulars', name: 'bijzonderheden' }],
              }),
            },
          ]
        : []),
      ...(isModuleValuationVisible
        ? [
            {
              key: 'valuation',
              name: 'indicatie marktwaarde',
              subSections: [
                ...(valuationData.prijsOntwikkeling
                  ? [
                      {
                        key: 'valuation-price-development',
                        name: 'prijsontwikkeling',
                      },
                    ]
                  : []),
                ...(valuationData?.prijsModel
                  ? [{ key: 'valuation-price-model', name: 'modelwaarde' }]
                  : []),
                ...(referencesFromStore?.length > 0 &&
                objectDetailsFromStore?.woonoppervlakte
                  ? [
                      {
                        key: 'valuation-price-calculation',
                        name: 'referentiewaarde',
                      },
                    ]
                  : []),
              ],
            },
          ]
        : []),
      ...(referencesFromStore?.length > 0
        ? [{ key: 'references', name: 'referenties' }]
        : []),
      ...(Object.keys(marketFromStore)?.length
        ? [{ key: 'market', name: 'markt' }]
        : []),
      ...(Object.keys(demographicsFromStore)?.length
        ? [{ key: 'demographics', name: 'doelgroepen' }]
        : []),
    ]
  }, [
    demographicsFromStore,
    isModuleValuationVisible,
    marketFromStore,
    objectDetailsFromStore,
    referencesFromStore?.length,
    valuationData?.prijsModel,
    valuationData.prijsOntwikkeling,
  ])

  useEffect(() => {
    trackPageView(
      'visit_page_object',
      `Objectpage opened of object: ${objectId}`,
    )

    if (!hasObject) {
      getUserData(userName, objectId)
    }

    if (!hasRecentVisits) {
      getUserData(userName, 'recent-visits')
    }

    // We don't want the side effects of `getUserData` triggering this `useEffect`.
    // We only want to trigger this `useEffect` one time, when mounting this page.
    // eslint-disable-next-line
  }, [objectId])

  function closeModal() {
    history.push(baseUrl)
  }

  function showNoteModal(panelName) {
    trackEvent(
      'clicked_open_modal_note',
      gaCategories.NOTES,
      `Note modal opened for ${panelName}`,
    )

    history.push(`${baseUrl}/note/${panelName}`)
  }

  function printSettingChange(event, setting) {
    event.target.checked
      ? dispatch(deleteExcludedItem(setting))
      : dispatch(setExcludedItem(setting))
  }

  function onExportReset() {
    dispatch(deleteAllExcludedItems())
    closeModal()
  }

  useEffect(() => {
    if (excludedPrintItems.includes('particulars')) {
      setRenderReferencesNotToExport(true)
    } else {
      setRenderReferencesNotToExport(false)
    }
  }, [excludedPrintItems])

  function printPage() {
    window.print()
  }

  function saveNote(savedNote) {
    setUserData(userName, objectId, { notes: savedNote })
  }

  useEffect(() => {
    dispatch(showExportButton())
  }, [dispatch])

  const { pathname } = location

  const tabs = tabElements(pathname)

  const onTabClicked = key => {
    trackEvent(
      `clicked_tab_to_navigate_to_${key}`,
      gaCategories.NAVIGATION,
      `Clicked tab to navigate to ${key}`,
    )
  }

  const ratingsUrl =
    window.deploy_env === 'prd'
      ? 'https://ratings.brainbay.nl/widget?appName=Inverkoopname&widgetToken=394f6480-c8a3-4a33-9c6f-2826f9b431fc'
      : 'https://ratings.acc.brainbay.nl/widget?appName=Inverkoopname&widgetToken=1234'

  return (
    <>
      <Switch>
        <Route path={`${baseUrl}/export`}>
          <Modal
            onClose={onExportReset}
            title="Exporteren"
            subTitle="Welke onderdelen wil je exporteren?"
          >
            <PrintSettingsForm
              onPrintPdf={printPage}
              onDownloadJson={canExportJson && makeJSON}
              onFormSubmit={() => makePDF(graphRef)}
              excludedPrintItems={excludedPrintItems}
              onChangeCheckbox={printSettingChange}
              objectSections={exportObjectSections}
              showSmallerSections
            />
          </Modal>
        </Route>
      </Switch>

      <main className="layout--grid" key={objectId}>
        <TabbedContent
          tabs={tabs}
          selectedTab={toolName.INVERKOOPNAME}
          onTabClicked={onTabClicked}
          className="object__tabbed-content-wrapper print-excluded"
        />
        {!isLoading &&
          isOldTransaction &&
          objectDetailsFromStore &&
          !objectDetailsHasChanged && (
            <NotificationPanel
              notification={`Bron: Midas ${aanmeldYear}. Deze aanmelding is ${ageOfObject} jaar oud en woningkenmerken kunnen verouderd zijn. Dit geldt met name voor woonoppervlak en woningkwaliteit. Controleer de kenmerken en pas desgewenst aan.`}
              className="object__notification"
            />
          )}

        {!isLoading &&
          isBAGObject &&
          objectDetailsFromStore &&
          !objectDetailsHasChanged && (
            <NotificationPanel
              notification={`Bron: BAG ${new Date().getFullYear()}. Dit adres is onbekend in de Midas-database. Deze data komt uit Basisadministratie Adressen en Gebouwen. Controleer de kenmerken en pas desgewenst aan.`}
              className="object__notification"
            />
          )}

        <ObjectDetails
          objectId={objectId}
          baseUrl={baseUrl}
          objectDetailsHasChanged={objectDetailsHasChanged}
          onShowNoteModal={showNoteModal}
          onSaveNote={saveNote}
          onCloseModal={closeModal}
          renderReferencesNotToExport={renderReferencesNotToExport}
        />

        <History
          objectId={objectId}
          baseUrl={baseUrl}
          onCloseModal={closeModal}
        />

        <Valuation
          ref={graphRef}
          objectId={objectId}
          baseUrl={baseUrl}
          onShowNoteModal={showNoteModal}
          onSaveNote={saveNote}
          onCloseModal={closeModal}
        />

        <References
          objectId={objectId}
          baseUrl={baseUrl}
          onShowNoteModal={showNoteModal}
          onSaveNote={saveNote}
          onCloseModal={closeModal}
        />

        <MarketValue
          className="grid--align-left"
          objectId={objectId}
          baseUrl={baseUrl}
          housingType={housingType}
          addressId={addressId}
          onShowNoteModal={showNoteModal}
          onSaveNote={saveNote}
          onCloseModal={closeModal}
        />

        <Demographics
          className="grid--align-right"
          objectId={objectId}
          baseUrl={baseUrl}
          housingType={housingType}
          addressId={addressId}
          onShowNoteModal={showNoteModal}
          onSaveNote={saveNote}
          onCloseModal={closeModal}
        />

        <button
          className="grid--align-center button button--secondary print-excluded"
          onClick={props.onExport}
        >
          Exporteren
        </button>

        <Feedback
          className="landing__feedback print-excluded"
          src={ratingsUrl}
        />

        <Panel title="Disclaimer" className="object__disclaimer print-only">
          <p>
            Dit verkooprapport is geen taxatierapport. De getoonde informatie
            komt uit de woningdatabase van NVM/brainbay. Deze database bevat
            informatie die is gebruikt voor de woningpresentatie op funda. Door
            brainbay wordt deze data gebruikt voor analyses, bijvoorbeeld om
            woningen modelmatig te waarderen.
          </p>
          <p>
            Een modelwaarde is een door de computer berekende waarde. Voor deze
            modelmatige waardering wordt gebruik gemaakt van de gegevens van uw
            woning zoals woon- en perceeloppervlakte, aantal kamers,
            energielabel et cetera.
          </p>
          <p>
            Uw makelaar heeft geen enkele invloed op de aangegeven modelwaarden.
          </p>
          <p>
            De modelwaarden in het verkooprapport worden door uw makelaar
            beoordeeld. De makelaar voorziet deze zo nodig van een aanvullende
            uitleg of toelichting.
          </p>
        </Panel>
      </main>
    </>
  )
}
