import React from 'react'
import { Text, DefaultLayout } from '@brainbay/components/utils/pdf-components'

export default function DisclaimerPage({
  corporateIdentity,
  toolName,
  userName,
  date,
  debug,
}) {
  return (
    <DefaultLayout
      pageName="Disclaimer"
      corporateIdentity={corporateIdentity}
      toolName={toolName}
      userName={userName}
      date={date}
      debug={debug}
    >
      <Text
        style={{
          fontSize: 10,
          marginTop: 40,
        }}
        debug={debug}
      >
        Dit rapport is gegenereerd op {date}, gebruikmakend van de informatie
        die op deze datum bij brainbay beschikbaar was. Aan de uitkomsten van de
        gebruikte modellen kunnen geen rechten worden ontleend.
      </Text>
      <Text style={{ marginTop: 10, fontSize: 10 }}>
        Dit verkooprapport is geen taxatierapport. De getoonde informatie komt
        uit de woningdatabase van NVM/brainbay. Deze database bevat informatie
        die is gebruikt voor de woningpresentatie op funda. Door brainbay wordt
        deze data gebruikt voor analyses, bijvoorbeeld om woningen modelmatig te
        waarderen.
      </Text>
      <Text style={{ marginTop: 10, fontSize: 10 }}>
        Een modelwaarde is een door de computer berekende waarde. Voor deze
        modelmatige waardering wordt gebruik gemaakt van de gegevens van uw
        woning zoals woon- en perceeloppervlakte, aantal kamers, energielabel et
        cetera.
      </Text>
      <Text style={{ marginTop: 10, fontSize: 10 }}>
        Uw makelaar heeft geen enkele invloed op de aangegeven modelwaarden.
      </Text>
      <Text style={{ marginTop: 10, fontSize: 10 }}>
        De modelwaarden in het verkooprapport worden door uw makelaar
        beoordeeld. De makelaar voorziet deze zo nodig van een aanvullende
        uitleg of toelichting.
      </Text>
    </DefaultLayout>
  )
}
