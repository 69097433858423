import { useSelector, useDispatch } from 'react-redux'
import formatAddress from '@brainbay/components/utils/format-address'
import downloadJson from '@brainbay/components/utils/download-json'
import isSSR from '@brainbay/components/utils/is-ssr'
import { generatePDFDocument } from '@brainbay/components/utils/generate-pdf-document'
import { corporateIdentities } from '@brainbay/components/utils/constants'
import PDFDocument from './pdf-components/pdf-document'
import useReference from './use-reference'
import { setErrorMessage } from '../store/error-message'
import { post } from './api-data'

export default function useExport() {
  const dispatch = useDispatch()
  const objectDetailsFromStore = useSelector(
    state => state?.objectDetails.objectDetails,
  )
  const objectId =
    objectDetailsFromStore?.objectGuid || objectDetailsFromStore?.id
  const user = useSelector(state => state?.user)
  const excludedPrintItems = useSelector(state => state.printSettings)
  const { selectedReferences: selectedReferencesFromStore } = useReference()
  const notes = useSelector(state => state.user?.data?.[objectId]?.notes)

  const address = formatAddress({
    street: objectDetailsFromStore?.straatnaam,
    houseNumber: objectDetailsFromStore?.huisnummer,
    houseNumberSuffix: objectDetailsFromStore?.huisnummertoevoeging,
    city: objectDetailsFromStore?.woonplaats,
    postalCode: objectDetailsFromStore?.postcode,
  })

  async function getExportData(apiEndpoint) {
    const exportDataFormat = {
      woningDetails:
        excludedPrintItems.indexOf('object-details') === -1
          ? {
              includeNotes:
                excludedPrintItems.indexOf('object-details-note') === -1,
            }
          : null,
      marktWaarde:
        excludedPrintItems.indexOf('valuation') === -1
          ? {
              includePrijsontwikkelingWaarde:
                excludedPrintItems.indexOf('valuation-price-development') ===
                -1,
              includeModelWaarde:
                excludedPrintItems.indexOf('valuation-price-model') === -1,
              includeNotes: excludedPrintItems.indexOf('valuation-note') === -1,
            }
          : null,
      referenties: {
        includeReferenties: excludedPrintItems.indexOf('references') === -1,
        includeReferentiesMarktWaarde:
          excludedPrintItems.indexOf('valuation') === -1 &&
          excludedPrintItems.indexOf('valuation-price-calculation') === -1,
        includeNotes:
          excludedPrintItems.indexOf('references') === -1 &&
          excludedPrintItems.indexOf('references-note') === -1,
      },
      marktAnalyse:
        excludedPrintItems.indexOf('market') === -1
          ? {
              includeNotes: excludedPrintItems.indexOf('market-note') === -1,
            }
          : null,
      doelgroepen:
        excludedPrintItems.indexOf('demographics') === -1
          ? {
              includeNotes:
                excludedPrintItems.indexOf('demographics-note') === -1,
            }
          : null,
    }

    return await post({
      path: `${apiEndpoint}/${objectId}`,
      body: exportDataFormat,
    })
  }

  async function makeJSON() {
    try {
      const exportData = await getExportData('exports')
      downloadJson(exportData, `Verkoopadvies ${address}.json`)
      return
    } catch {
      dispatch(
        setErrorMessage(
          'Er is iets misgegaan. We kunnen op dit moment geen JSON export aanmaken. Probeer het later opnieuw.',
        ),
      )
      return
    }
  }

  async function makePDF(graphSVGRef) {
    if (!isSSR) {
      try {
        const response = await getExportData('exports/internal')
        const data = response?.data || response || null

        const exportedObjectData = {
          objectDetails: data?.woningDetails,
          selectedReferences: data?.referentieObjects.referentieObjects,
          market: data?.markt,
          valuation: data?.indicatieMarktwaarde,
          demographics: data?.doelgroepen,
        }

        const storeData = {
          objectDetailsFromStore,
          selectedReferencesFromStore,
        }

        await generatePDFDocument(
          `Verkooprapport ${address}.pdf`,
          corporateIdentities.BRAINBAY,
          <PDFDocument
            user={user}
            address={address}
            object={exportedObjectData}
            exportSettings={excludedPrintItems}
            graphSVG={graphSVGRef}
            storeData={storeData}
            notes={notes}
          />,
        )
      } catch {
        dispatch(
          setErrorMessage(
            'Er is iets misgegaan. We kunnen op dit moment geen PDF export aanmaken. Probeer het later opnieuw.',
          ),
        )
        return
      }
    }
    return
  }

  return {
    makePDF,
    makeJSON,
  }
}
