import React from 'react'
import {
  AddressTypeAndQualityCircles,
  Canvas,
  DefaultLayout,
  DetailPageSlots,
  Image,
  View,
} from '@brainbay/components/utils/pdf-components'
import { useSlotOrder } from '../helpers'
import { apiHost } from '@brainbay/components/utils/environment-vars'

export default function BuildingDetailPage({
  corporateIdentity,
  item,
  toolName,
  userName,
  showParticulars,
  date,
  debug,
}) {
  const address = item?.adres?.adresString

  const slotElements = useSlotOrder(item, showParticulars)

  const imageEndpoint = item?.media ? 'media' : 'streetview'
  const imageId = item?.media ? item?.media : address

  return (
    <DefaultLayout
      pageName="Woningdetails"
      corporateIdentity={corporateIdentity}
      toolName={toolName}
      userName={userName}
      date={date}
      smallPaddingHorizontal={true}
      debug={debug}
    >
      <View>
        <View style={{ paddingHorizontal: 30 }}>
          <AddressTypeAndQualityCircles
            item={item}
            address={address}
            debug={debug}
          />
          <Canvas
            paint={painter =>
              painter.moveTo(0, 0).lineTo(450, 0).lineWidth(1.5).stroke('#000')
            }
            style={{ marginTop: 10 }}
          />
          <DetailPageSlots slotElements={slotElements} debug={debug} />
          <Image
            src={`${apiHost()}/${imageEndpoint}/700x467/${imageId}`}
            style={{
              width: '100%',
              marginTop: 30,
              position: 'relative',
            }}
            allowDangerousPaths
            break
          />
        </View>
      </View>
    </DefaultLayout>
  )
}
