import React from 'react'
import formatValue from '@brainbay/components/utils/format-value'
import Document from '@brainbay/components/utils/pdf-components/document'
import { corporateIdentities } from '@brainbay/components/utils/constants'
import {
  FrontPage,
  MarketAnalysisPage,
  ReferencesPage,
  UserNotesPage,
  ValuationPage,
} from '@brainbay/components/utils/pdf-components/pages'
import { BuildingDetailPage, DemographicsPage, DisclaimerPage } from './pages'
import { notesSlotDataOrder } from './helpers'

export default function PDFDocument({
  user,
  address,
  object,
  exportSettings,
  graphSVG,
  debug,
  storeData,
  notes,
}) {
  const userName = user?.user?.name || user?.user?.sub || '---'
  const date = formatValue(new Date().toString(), { format: 'date' })
  const toolName = 'Verkooprapport'
  const particulars = storeData?.objectDetailsFromStore?.bijzonderheden || []
  const objectDetails = {
    ...object?.objectDetails,
    bijzonderheden: particulars,
  }

  return (
    <Document>
      <FrontPage
        corporateIdentity={corporateIdentities.BRAINBAY}
        media={storeData?.objectDetailsFromStore?.mediaGuid}
        address={address}
        userName={userName}
        toolName={toolName}
        date={date}
        debug={debug}
      />
      {!exportSettings.includes('object-details') && (
        <BuildingDetailPage
          corporateIdentity={corporateIdentities.BRAINBAY}
          item={objectDetails}
          toolName={toolName}
          userName={userName}
          showParticulars={!exportSettings.includes('particulars')}
          date={date}
          debug={debug}
        />
      )}
      {!exportSettings.includes('valuation') && (
        <ValuationPage
          corporateIdentity={corporateIdentities.BRAINBAY}
          item={object?.valuation}
          toolName={toolName}
          userName={userName}
          storeData={storeData}
          exportSettings={exportSettings}
          graphSVG={graphSVG}
          date={date}
          debug={debug}
        />
      )}
      {!exportSettings.includes('references') &&
        object?.selectedReferences?.length > 0 && (
          <ReferencesPage
            corporateIdentity={corporateIdentities.BRAINBAY}
            item={object?.selectedReferences}
            objectDetails={objectDetails}
            toolName={toolName}
            userName={userName}
            date={date}
            debug={debug}
          />
        )}
      {!exportSettings.includes('market') && object?.market && (
        <MarketAnalysisPage
          corporateIdentity={corporateIdentities.BRAINBAY}
          item={object?.market}
          toolName={toolName}
          userName={userName}
          date={date}
          debug={debug}
        />
      )}
      {!exportSettings.includes('demographics') && object?.demographics && (
        <DemographicsPage
          corporateIdentity={corporateIdentities.BRAINBAY}
          item={object?.demographics}
          toolName={toolName}
          userName={userName}
          date={date}
          debug={debug}
        />
      )}
      {notes && Object.keys(notes).length > 0 && (
        <UserNotesPage
          corporateIdentity={corporateIdentities.BRAINBAY}
          toolName={toolName}
          userName={userName}
          date={date}
          notes={notes}
          slotDataOrder={notesSlotDataOrder}
        />
      )}
      <DisclaimerPage
        corporateIdentity={corporateIdentities.BRAINBAY}
        item={object}
        toolName={toolName}
        userName={userName}
        date={date}
        debug={debug}
      />
    </Document>
  )
}
